<template>
  <b-container>
    <transition name="slide-fade">
      <b-row v-if="renderPage">
        <b-col
          class="ml-auto"
          xl="auto"
          md="12"
          sm="12"
          lg="auto"
        >
          <b-card
            class="fix-card"
            title="Informações"
          >

            <b-form-group label="Nome do Perfil:">
              <span class="font-weight-bold">
                {{ profileDetails.profile_name }}</span>
            </b-form-group>
            <b-form-group
              v-if="backofficeUsers.length"
              label="Usuários Relacionados:"
            >
              <b-list-group
                v-for="item in backofficeUsers"
                :key="item.id"
                flush
              >
                <b-list-group-item>{{ item.name_user }}</b-list-group-item>
              </b-list-group>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col
          class="mr-auto"
          xl="6"
          md="12"
          sm="12"
          lg="6"
        >
          <b-card title="Editar Perfil">
            <b-form
              novalidate
              class="needs-validation"
              @submit.prevent
            >
              <b-form-group
                label="Nome do Perfil"
                label-for="name_profile"
              >
                <b-form-input
                  id="name_profile"
                  v-model="profileName"
                  placeholder="Informe o nome do Perfil"
                />
              </b-form-group>
            </b-form>

            <b-form-group label="Escolha os Itens do Menu">
              <div
                v-for="(item, key, index) in options"
                :key="index"
                class="mb-1"
              >
                <permission
                  :options="[...item]"
                  :item="key"
                  :permissions="permissions"
                  @update="updatePermission"
                />
              </div>
            </b-form-group>

            <b-row class="mt-1">
              <b-col
                md="6"
                sm="6"
                class="mt-1"
              >
                <b-button
                  variant="primary"
                  block
                  @click="showConfirm"
                >Salvar</b-button>
              </b-col>
              <b-col
                md="6"
                sm="6"
                class="mt-1"
              >
                <b-button
                  variant="primary"
                  :to="{ name: 'usuarios' }"
                  block
                >Voltar</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </transition>
  </b-container>
</template>
<script>
import {
    BContainer,
    BRow,
    BFormGroup,
    BFormInput,
    BCard,
    BButton,
    BCol,
    BForm,
    BOverlay,
    BIcon,
    BSpinner,
    BListGroup,
    BListGroupItem,
} from 'bootstrap-vue';
import { getProfile, updateProfile } from '@/service/profile/profile';

import PermissionService from '@/service/permission';

import Permission from './components/Permissions.vue';

export default {
    components: {
        BForm,
        BContainer,
        BRow,
        BFormGroup,
        BFormInput,
        BCard,
        BButton,
        BSpinner,
        BCol,
        BOverlay,
        BIcon,
        BListGroup,
        BListGroupItem,
        Permission,
    },
    data() {
        return {
            renderPage: true,
            showLoading: true,

            options: {},

            profileDetails: [],
            backofficeUsers: [],
            profileList: [],
            selectedPermission: [],
            selectedPermissionDashboard: [],

            permissions: [],

            //

            profileName: null,
        };
    },
    computed: {
        permissionsCopy() {
            return [...this.permissions];
        },
    },
    watch: {
    },

    async mounted() {
        this.showLoading = true;
        await Promise.all([
            this.getProfile(),
            this.getPermissions(),
        ]);
        this.showLoading = false;
    },
    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },
    methods: {
        async getPermissions() {
            const { data: { data } } = await PermissionService.index();

            this.options = (data.sort((a, b) => {
                if (a.permission_group && b.permission_group) {
                    return a.permission_group.description.localeCompare(b.permission_group.description);
                } if (a.permission_group && !b.permission_group) {
                    return 0;
                } if (b.permission_group && !a.permission_group) {
                    return 1;
                }

                return 1;
            }).reduce((acc, item) => {
                if (!item.permission_in_portuguese) return acc;
                if (!item.permission_group) {
                    acc[item.permission_in_portuguese] = item;
                } else {
                    if (!acc[item.permission_group.description]) acc[item.permission_group.description] = [];
                    acc[item.permission_group.description].push(item);
                }
                return acc;
            }, {}));
        },
        showConfirm() {
            this.$bvModal
                .msgBoxConfirm('Deseja Salvar as Informações?', {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) {
                        this.updateProfile();
                    }
                });
        },
        async updateProfile() {
            try {
                const { id } = this.$route.params;
                this.msg = 'Criando novo Perfil';
                this.showLoading = true;

                const {
                    profileName, permissions,
                } = this;

                const uniquePermissionsSet = new Set(permissions);
                const uniquePermissionsArray = [...uniquePermissionsSet];

                const response = await updateProfile({
                    id,
                    profileName,
                    permissions: uniquePermissionsArray,
                });

                if (response === 200) {
                    this.success();
                    await this.getProfile();
                } else {
                    this.error(`Não foi Possível Editar o perfil ${profileName}!`);
                }
            } finally {
                this.showLoading = false;
            }
        },
        async getProfile() {
            const { id } = this.$route.params;
            const { data, status } = await getProfile(id);

            if (status !== 200) {
                this.$router.push('/error-404', status);
                return;
            }

            this.profileDetails = data;

            const { backoffice_users, permissions, profile_name } = data;
            const backofficeList = backoffice_users.map(user => ({
                id: user.user.id,
                name_user: user.user.name,
            }));

            this.permissions = permissions.map(permission => permission.permission_id);

            this.profileName = profile_name;
            this.backofficeUsers = backofficeList.sort((a, b) => (a.name_user.localeCompare(b.name_user)));
            this.showLoading = false;
        },
        selectedAll() {
            if (this.allSelectedDashboard) {
                this.selectedPermissionDashboard = this.optionsDashboards.map(option => option.value);
            } else {
                this.selectedPermissionDashboard = [];
            }
        },
        success() {
            this.$swal({
                icon: 'success',
                html: '<span>Perfil editado com sucesso! </span> ',
                customClass: {
                    confirmButton: 'btn btn-primary btn-modal',
                },
                buttonsStyling: false,
            });
        },
        error(msg) {
            this.$swal({
                icon: 'error',
                html: `<span> ${msg} </span> `,
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },

        updatePermission({ all, calculated }) {
            const { permissions } = this;
            const valoresPresentes = permissions.filter(valor => !all.includes(valor));

            calculated.forEach(item => valoresPresentes.push(item));

            this.permissions = valoresPresentes;
        },
    },
};
</script>
  <style lang="scss" scoped>
  @import "@core/scss/vue/pages/new-client.scss";

  </style>
